/* We are overwriting some vars defined here: remirror/styles/all.css */
.remirror-theme {
    --rmr-color-background: #ffffff;
    --rmr-color-primary: #162FEF;
    --rmr-color-hover-background: #162FEF; 
    --rmr-color-hover-primary: #162FEF; 
    --rmr-color-hover-primary-text: white;
    --rmr-color-border: #DEE3EC;
    --rmr-color-background-disabled: #C8D0DF;
    /* Equivalent to $dark */
    --rmr-color-text: #303F4B;
  }

  /* This is required because Remirror is placing the tooltip at the DOOM root */
  .remirror-tooltip {
    background-color: white;
    /* Equivalent to $dark */
    color: #303F4B; 
    /* Equivalent to $popup */
    box-shadow: 0 0 0.625rem 0 #00000020 !important;
  }
